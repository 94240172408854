import React, {Component} from "react"
import DefaultLayout from "../bosom/components/layout/default/DefaultLayout";
import AboutUs from "../views/about-us/AboutUs";
import ALS from "../views/als/ALS";

class AboutUsPage extends Component {
    constructor(props) {
        super(props);
        const previewUrl = this.props.pageContext.data.exercises["wrist-raises"];
        const sampleSvgPath = previewUrl + "/exercise.svg";

        this.state = {
            pageContext: this.props.pageContext,
            previewUrl : previewUrl,
            svgPath : sampleSvgPath
        };
    };

    render() {
        return (
            <DefaultLayout title={"About ALS"} context={this.props.pageContext}>
                <ALS/>
            </DefaultLayout>
        )
    }
}

export default AboutUsPage;
