import React from "react"
import AlsHeroSvg from "./assets/AlsHeroSvg";
import OneTwoBox from "../../bosom/components/layout/boxes/one-two-box/OneTwoBox";

const ALS = (props) => {
    const pStyle = {'margin': "0.5rem 0 0 0"};
    const hStyle = {'margin': "1.5rem 0 0 0"};

    return (
        <OneTwoBox svg={<AlsHeroSvg/>}
                   title={"About ALS"}
                   description={"Amyotrophic lateral sclerosis or ALS, is a progressive nervous system disease that affects nerve cells in the brain and spinal cord, causing loss of muscle control. ALS is often called Lou Gehrig's disease, after the baseball player who was diagnosed with it. Doctors usually don't know why ALS occurs. ALS often begins with muscle twitching and weakness in a limb, or slurred speech. Eventually, ALS affects control of the muscles needed to move, speak, eat and breathe. There is no cure for this fatal disease at this time."}>
            <h3 style={hStyle}>Symptoms</h3>
            <p style={pStyle}>Signs and symptoms of ALS vary greatly from person to person, depending on which neurons
                are affected. Signs and symptoms might include:</p>

            <ul>
                <li style={hStyle}>Difficulty walking or doing normal daily activities</li>
                <li style={pStyle}>Tripping and falling</li>
                <li style={pStyle}>Weakness in your leg, feet or ankles</li>
                <li style={pStyle}>Hand weakness or clumsiness</li>
                <li style={pStyle}>Slurred speech or trouble swallowing</li>
                <li style={pStyle}>Muscle cramps and twitching in your arms, shoulders and tongue</li>
                <li style={pStyle}>Inappropriate crying, laughing or yawning</li>
                <li style={pStyle}>Cognitive and behavioral changes</li>
            </ul>

            <p style={pStyle}>ALS often starts in the hands, feet or limbs, and then spreads to other parts of your
                body. As the disease advances and nerve cells are destroyed, your muscles get weaker. This eventually
                affects chewing, swallowing, speaking and breathing.</p>
            <p style={pStyle}>There's generally no pain in the early stages of ALS, and pain is uncommon in the later
                stages. ALS doesn't usually affect your bladder control or your senses.</p>

            <h3 style={hStyle}>Causes</h3>
            <p style={pStyle}>ALS affects the nerve cells that control voluntary muscle movements such as walking and
                talking (motor neurons). ALS causes the motor neurons to gradually deteriorate, and then die. Motor
                neurons extend from the brain to the spinal cord to muscles throughout the body. When motor neurons are
                damaged, they stop sending messages to the muscles, so the muscles can't function.</p>

            <p style={pStyle}>ALS is inherited in 5% to 10% of people. For the rest, the cause isn't known.</p>
            <p style={pStyle}>Researchers continue to study possible causes of ALS. Most theories center on a complex
                interaction between genetic and environmental factors.</p>

            <h3 style={hStyle}>Risk factors</h3>
            <p style={pStyle}>Established risk factors for ALS include:</p>

            <ul>
                <li style={hStyle}><strong>Heredity</strong> - Five to 10 percent of the people with ALS inherited it
                    (familial ALS). In most people with familial ALS, their children have a 50-50 chance of developing
                    the disease.
                </li>
                <li style={pStyle}><strong>Age</strong> - ALS risk increases with age, and is most common between the
                    ages of 40 and the mid-60s.
                </li>
                <li style={pStyle}><strong>Sex</strong> - Before the age of 65, slightly more men than women develop
                    ALS. This sex difference disappears after age 70.
                </li>
                <li style={pStyle}><strong>Genetics</strong> - Some studies examining the entire human genome found many
                    similarities in the genetic variations of people with familial ALS and some people with noninherited
                    ALS. These genetic variations might make people more susceptible to ALS.
                </li>
            </ul>

            <p style={pStyle}>Environmental factors, such as the following, might trigger ALS.</p>

            <ul>
                <li style={hStyle}><strong>Smoking</strong> - Smoking is the only likely environmental risk factor for
                    ALS. The risk seems to be greatest for women, particularly after menopause.
                </li>
                <li style={pStyle}><strong>Environmental toxin exposure</strong> - Some evidence suggests that exposure
                    to lead or other substances in the workplace or at home might be linked to ALS. Much study has been
                    done, but no single agent or chemical has been consistently associated with ALS.
                </li>
                <li style={pStyle}><strong>Military service</strong> - Studies indicate that people who have served in
                    the military are at higher risk of ALS. It's unclear what about military service might trigger the
                    development of ALS. It might include exposure to certain metals or chemicals, traumatic injuries,
                    viral infections, and intense exertion.
                </li>
            </ul>

            <h3 style={hStyle}>Complications</h3>
            <p style={pStyle}>As the disease progresses, ALS causes complications, such as:</p>

            <h4 style={hStyle}>Breathing problems</h4>
            <p style={pStyle}>Over time, ALS paralyzes the muscles you use to breathe. You might need a device to help
                you breathe at night, similar to what someone with sleep apnea might wear. For example, you may be given
                continuous positive airway pressure (CPAP) or bilevel positive airway pressure (BiPAP) to assist with
                your breathing at night.</p>
            <p style={pStyle}>Some people with advanced ALS choose to have a tracheostomy — a surgically created hole at
                the front of the neck leading to the windpipe (trachea) — for full-time use of a respirator that
                inflates and deflates their lungs.</p>
            <p style={pStyle}>The most common cause of death for people with ALS is respiratory failure. On average,
                death occurs within three to five years after symptoms begin. However, some people with ALS live 10 or
                more years.</p>

            <h4 style={hStyle}>Speaking problems</h4>
            <p style={pStyle}>Most people with ALS develop trouble speaking. This usually starts as occasional, mild
                slurring of words, but becomes more severe. Speech eventually becomes difficult for others to
                understand, and people with ALS often rely on other communication technologies to communicate.</p>

            <h4 style={hStyle}>Eating problems</h4>
            <p style={pStyle}>People with ALS can develop malnutrition and dehydration from damage to the muscles that
                control swallowing. They are also at higher risk of getting food, liquids or saliva into the lungs,
                which can cause pneumonia. A feeding tube can reduce these risks and ensure proper hydration and
                nutrition.</p>

            <h4 style={hStyle}>Dementia</h4>
            <p style={pStyle}>Some people with ALS have problems with memory and decision-making, and some are
                eventually diagnosed with a form of dementia called frontotemporal dementia.</p>

            <h3 style={hStyle}>FAQ</h3>

            <h5 style={hStyle}>Is there a cure?</h5>
            <p style={pStyle}>Currently there is no cure for ALS, however, medication and therapy can slow ALS, reduce
                discomfort, and improve quality of life.</p>

            <h5 style={hStyle}>Can ALS be prevented?</h5>
            <p style={pStyle}>There are no preventive steps known at this time. It's rare, affecting about 5.2 people
                per 100,000 in the U.S. population, according to the National ALS Registry.</p>

            <h5 style={hStyle}>Has anyone ever recovered from ALS?</h5>
            <p style={pStyle}>ALS is a debilitating, devastating disease from which no one has ever fully recovered.</p>
            <h5 style={hStyle}>What are the 3 types of ALS?</h5>
            <ul>
                <li style={hStyle}><strong>Sporadic</strong> - approximately 90% cases of ALS are called “sporadic,”
                    meaning the cause or causes of the disease are unknown.
                </li>
                <li style={pStyle}><strong>Familial</strong> - in which there is a family history of the disease.
                    Familial ALS constitutes 5 to 10% of all cases of ALS. There are a number of different types of
                    familial ALS. They are known as ALS1, ALS2, and so on.
                </li>
                <li style={pStyle}><strong>Guamanian</strong> - cases observed in Guam and the Trust Territories of the
                    Pacific with extremely high incidence rate.
                </li>
            </ul>

            <h5 style={hStyle}>Do early ALS symptoms come and go?</h5>
            <p style={pStyle}>ALS symptoms are progressive meaning the symptoms get worse over time and often develop
                very quickly. That said there are some cases in which symptoms, such as difficulty swallowing, can get
                better for a period of time.</p>

            <h5 style={hStyle}>Can ALS go into remission?</h5>
            <p style={pStyle}>Difficult to say. Each person with ALS experiences a different proportion of upper and
                lower motor neurons that die. This results in symptoms that vary from person to person. The disease
                progresses, affecting more nerve cells as time goes on.</p>

            <h5 style={hStyle}>Is there any hope for ALS patients?</h5>
            <p style={pStyle}>Yes, there is a palpable sense of hope in ALS science circles these days. And that
                optimism very much includes a fingers-crossed suspicion that treatment advances are just up ahead on the
                research horizon.</p>

            <h5 style={hStyle}>How fast does ALS progress?</h5>
            <p style={pStyle}>Over the course of months to years, in the majority of situations, the weakness spreads
                throughout the body until all of the person's limbs are paralysed. For each person, this progression
                occurs at a steady rate. However, the overall rate of progression can still be different from one
                patient to the next.</p>

            <h5 style={hStyle}>Do PALS feel pain?</h5>
            <p style={pStyle}>Pain in ALS most frequently involves musculoskeletal pain that occurs in the back, legs,
                arms, shoulder, and neck. Although the etiology of this pain is not well understood, it is known that
                musculoskeletal pain in ALS develops secondary to muscle atrophy and decreased muscle tone.</p>

            <h5 style={hStyle}>Does exercise help with ALS?</h5>
            <p style={pStyle}>Exercise may have many benefits for ALS patients, including reducing depression and
                improving strength and stamina.</p>

            <h5 style={hStyle}>Does ALS affect memory?</h5>
            <p style={pStyle}>Most experts believe that ALS usually does not affect a person's mental processes. In most
                people, neither cognitive processes (such as thinking, learning, memory, and comprehension) nor behavior
                is affected.</p>

            <h5 style={hStyle}>Can stem cells cure ALS?</h5>
            <p style={pStyle}>Currently, there is no known cure for ALS, but stem cell-based therapies may give
                patients, their doctors and scientists hope in dealing with this condition.</p>

            <h5 style={hStyle}>Can coconut oil help with ALS?</h5>
            <p style={pStyle}>Coconut oil has plausible mechanisms for use in ALS involving raising ketone bodies and
                lipid levels. Ketogenic and high fat diets may have helped slow motor neuron loss in small ALS animal
                studies with many flaws.</p>

            <h5 style={hStyle}>What is life like with ALS?</h5>
            <p style={pStyle}>Most people live for at least 3 years after their diagnosis. Some people live up to 10
                years. As new medicines and treatments for symptoms become available, people will live longer and longer
                with the disease. Your health care team can help you understand what to expect.</p>


        </OneTwoBox>
    )
}

export default ALS;
